import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ReactGA from 'react-ga';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import theme from './theme';

import GameField from './components/GameField';
import InvalidGame from './components/InvalidGame';
  
const useStyles = makeStyles((theme) => ({
  copyright: {
    fontSize: 12, 
    margin: "0 2px"
  }
}));

function App() {
  const classes = useStyles();

  //Google Analytics
  ReactGA.initialize(
    [
      {
        trackingId: 'UA-46640819-28',
        gaOptions: { name: 'global' }
      },
      {
        trackingId: 'UA-46640819-29',
        gaOptions: { name: 'scorebook' }
      }
    ],
  );
  ReactGA.pageview(window.location.pathname + window.location.search, ['global']);
  ReactGA.pageview(window.location.pathname + window.location.search, ['scorebook']);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
            <Box component="img" src="NWL_textlogo.png" alt="NWL Logo" sx={{maxHeight: "50px", maxWidth: "100%"}} /> 
        </Toolbar>
      </AppBar>
      <Container 
        maxWidth="md" 
        style={{ marginTop: 20 }}>
        <Paper style={{ padding: 20 }}>
          <Router>
            <Switch>
              <Route path="/:id/">
                <GameField />
              </Route>
              <Route path="/">
                <InvalidGame />
              </Route>
            </Switch>
          </Router>
        </Paper>
        <Box 
          component="footer" 
          my="20px" 
          display="flex" 
          flexWrap="wrap" 
          justifyContent="center">
          <Typography 
            className={classes.copyright}>
            Copyright © {new Date().getFullYear()} Northwoods League. 
          </Typography>
          <Typography 
            className={classes.copyright}>
            All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;