import React, { 
    useState, 
    useEffect, 
    useRef 
} from "react";
import {
  useParams,
} from "react-router-dom";
import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import firebase from '../firebase';

import Loading from './Loading';
import NewGame from './NewGame';
import GameOver from './GameOver';
import InvalidGame from './InvalidGame';
import Score from './Score';
import LineScore from './LineScore';
import FieldSVG from './FieldSVG';
import LineupTabs from './LineupTabs';
import PlayByPlay from './PlayByPlay';

//General values
const finalStatus = [2, 3, 4];
const suspendedStatus = [5, 6];
const delayedStatus = [7, 8, 9];

//Custom Hook
function usePrevious(value) {  
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function GameField(){
  //State
  let {id} = useParams();
  const [game, setGame] = useState({loading: true});
  const [apiGame, setApiGame] = useState();
  const [battersTab, setBattersTab] = React.useState(0);
  const [pitchersTab, setPitchersTab] = React.useState(1);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [statusText, setStatusText] = React.useState('');
  const [playByPlay, setPlayByPlay] = React.useState([]);
  const [playByPlayTab, setPlayByPlayTab] = React.useState(0);
  const prevGame = usePrevious(game);

  //Event handlers
  const handleBattersTabChange = (event, newValue) => {
    setBattersTab(newValue);
  };

  const handlePitchersTabChange = (event, newValue) => {
    setPitchersTab(newValue);
  };

  const handlePlayByPlayTabChange = (event, newValue) => {
    setPlayByPlayTab(newValue);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  //Custom validations
  const checkTabsSide = () => {
    if(game.half && game.half !== prevGame.half){
      if(game.half === "BOTTOM"){
        setBattersTab(1);
        setPitchersTab(0);
      }
      else {
        setBattersTab(0);
        setPitchersTab(1);
      }
    }
  }

  const checkLastEvent = () => {
    setOpenSnackBar(game.lastEvent ? true : false);
  }

  const checkGameStatus = () => {
    if(suspendedStatus.includes(game?.status)){
      setOpenModal(true);
      setStatusText("Suspended");
    }
    else if(delayedStatus.includes(game?.status)){
      setOpenModal(true);
      setStatusText("Delayed");
    }
    else {
      setOpenModal(false);
    }
  }

  const checkPlayByPlay = () => {
    if(game.batter && game.batter.id !== prevGame.batter?.id){
      axios.get(`${process.env.REACT_APP_SB_API}game/${id}/playbyplay`)
        .then(res => {
          if(res.data.playbyplay.innings){
            let plays = [],
                data = res.data.playbyplay.innings;

            for(const item in data){
              plays.push(data[item]);
            }
            setPlayByPlay(plays);

            //Sets current pbp tab based on response
            if(plays.length)
              setPlayByPlayTab(plays.length - 1);
            else
              setPlayByPlayTab(0);
          }
        })
        .catch(err => {
          //Does nothing
        });
    }
  }

  //Hooks
  useEffect(() => {
    firebase.database().ref('/games/' + id).on('value', (snapshot) => {
      let g = snapshot.val();

      if(!apiGame && (!g || !g.teams || !g.currentlineups)){
        axios.get(`${process.env.REACT_APP_SB_API}game/${id}`)
        .then(res => {
            setApiGame(res.data.game);
          })
          .catch(err => {
            setApiGame({invalid: true});
          });
      }
      else {
        //Only sets game until it has the basic info
        if(g.teams && g.currentlineups)
          setGame(g);        
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Changes tabs
    checkTabsSide();

    //Shows last event
    checkLastEvent();

    //Shows Suspended/Delayed modal
    checkGameStatus();

    //Shows Play-by-Play
    checkPlayByPlay();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.half, game.lastEvent, game.status, game.batter]);

  //Component change based on state
  if(apiGame?.invalid)
    return <InvalidGame />

  if(game.loading && apiGame?.status_code === 0)
    return <NewGame game={apiGame} />

  if(finalStatus.includes(game?.status) || 
    finalStatus.includes(apiGame?.status_code))
    return <GameOver id={id} game={game} apiGame={apiGame} />

  if(game.loading)
    return <Loading />

  return (
    <div className="App">
      <header className="AppHeader">
        <Score game={game} />
        <LineScore game={game} />
        <Box 
          display="flex" 
          flexWrap="wrap" 
          justifyContent="center" 
          style={{ marginTop: 20 }}>
          <Typography 
            variant="h6" 
            style={{ padding: '0 5px' }}>
            {game.statusLine}
          </Typography>
          <Typography 
            variant="h6" 
            style={{ padding: '0 5px' }}>
            {game.balls}-{game.strikes} OUT {game.outs}
          </Typography>
        </Box>
      </header>
      <main 
        className="app-main"
        style={{ marginTop: 20 }}>
        <FieldSVG 
          fielders={game.currentlineups?.defense} 
          bases={game.bases} 
          batSide={game.batSide} 
          offenseColor={game.teams[game.offense]?.color} 
          defenseColor={game.teams[game.defense]?.color} />
        <Box 
          display="flex" 
          flexWrap="wrap" 
          justifyContent="center" 
          style={{ marginTop: 20 }}>
          <Typography 
            style={{ padding: '0 5px' }}>
            <b>Batter:</b> {game.batter ? `${game.batter.number} ${game.batter.name}` : '' }
          </Typography>
          <Typography 
            style={{ padding: '0 5px' }}>
            <b>Pitcher:</b> {game.pitcher ? `${game.pitcher.number} ${game.pitcher.name}` : '' } {game.pcLabel ? `(${game.pcLabel})` : ''}
          </Typography>
        </Box>
      </main>
      <LineupTabs 
        game={game}
        battersTab={battersTab}
        pitchersTab={pitchersTab}
        battersTabChange={handleBattersTabChange}
        pitchersTabChange={handlePitchersTabChange} />
      {playByPlay.length > 0 && 
        <PlayByPlay 
          playByPlay={playByPlay} 
          playByPlayTab={playByPlayTab}
          playByPlayTabChange={handlePlayByPlayTabChange} />
      }

      <Snackbar 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}} 
        open={openSnackBar} 
        autoHideDuration={4000} 
        onClose={handleSnackBarClose} 
        message={`Last Play: ${game.lastEvent ? game.lastEvent : ''}`} />
      <Dialog open={openModal}>
        <DialogContent>
          <Typography 
            variant="h6" 
            style={{padding: "20px 10px"}}>
            {`Game is ${statusText}`}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default GameField;