import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/analytics';

const config = {
    apiKey: "AIzaSyDaiy7PRJoIRXvHTWCbFv1CMpRLaL22Wvk",
    authDomain: "nwl-scorebook.firebaseapp.com",
    databaseURL: process.env.REACT_APP_RTDB_URL,
    projectId: "nwl-scorebook",
    appId: "1:874662405078:web:bc78953029c2076ddaba77",
    measurementId: "G-GGGEPF1Y7S"
};

firebase.initializeApp(config);
firebase.analytics();

export default firebase;