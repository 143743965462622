import React from "react";

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Score from './Score.js';

function updateObj(props){
  const res = {};
  const a = props.game;
  const b = props.apiGame;

  //Sets score
  res.score = (a && a.score) ? a.score : { visitor: b.visitor_result, home: b.home_result }

  //Sets teams
  res.teams = (a && a.teams) ? a.teams : { visitor: { name: b.visitor_team_name }, home: { name: b.home_team_name } }

  return res;
}

function GameOver(props){
  const game = updateObj(props);

  return (
    <React.Fragment>
      <Score game={game} />
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="25vh">
        <Typography variant="h6">
          Game Complete
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          href={`${process.env.REACT_APP_WP_URL}game?id=${props.id}`}
          style={{ marginTop: 20 }}>
          View Game Stats
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default GameOver;