import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: theme.palette.grey[100]
  },
  tabItem: {
    minWidth: 50
  }
}));

function PlayByPlay(props){
  const { 
    playByPlay, 
    playByPlayTab, 
    playByPlayTabChange } = props;
  const classes = useStyles();

  const playByPlayInnings = () => {
    return playByPlay.map((item, index) => {
      return (<Tab key={index} label={index + 1} className={classes.tabItem} />);
    });
  };

  const playByPlayInningTabs = () => {
    return playByPlay.map((item, index) => {
      return(
        <Box 
          hidden={playByPlayTab !== index} 
          p={2} 
          className={classes.tab} 
          key={index}>
          {playByPlayInning(item)}
        </Box>
      )
    });
  };

  const playByPlayInning = (inning) => {
    const res = [];
    for(const half in inning){
      res.push(
        <React.Fragment key={half}>
          <Typography variant="subtitle1">
            {half}
          </Typography>
          <List style={{ paddingBottom: 20 }}>
            {playByPlayHalf(inning[half])}
          </List>
        </React.Fragment>
      );
    }
    return res;
  }

  const playByPlayHalf = (half) => {
    const res = [];
    for(const p in half.players){
      let player = half.players[p];
      //Subs for current atbat
      if(half.subs[p]){
        for(const sub of half.subs[p]){
          res.push(
            <React.Fragment key={sub['name']}>
              <ListItem>
                <ListItemText
                  style={{ textAlign: 'center' }}
                  primary={`${sub['name']} ${(sub['isChange'] ? 'to' : 'came in as')} ${sub['position']}`} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          )
        }
      }
      res.push(
        <React.Fragment key={p}>
          <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={player.name} src={player.photo} />
              </ListItemAvatar>
              <ListItemText
                primary={`#${player.number} ${player.name}`}
                secondary={player.plays ? player.plays.join(', ') : ''} />
            </ListItem>
            <Divider variant="inset" component="li" />
        </React.Fragment>
      );
    }
    return res;
  }

  return (
    <React.Fragment>
      <Toolbar style={{ marginTop: 20 }}>
      <Typography variant="h6">
        Play by Play
      </Typography>
      </Toolbar>
      <AppBar position="static">
      <Tabs 
        value={playByPlayTab} 
        onChange={playByPlayTabChange} 
        variant="scrollable" 
        scrollButtons="auto">
        {playByPlayInnings()}
      </Tabs>
      </AppBar>
      {playByPlayInningTabs()}
    </React.Fragment>
  )
};

export default PlayByPlay;