import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const createTableHeaders = (innings) => {
  const res = [];

  for(let key=1; key <= innings; key++){
    res.push(<TableCell key={key} align="center">{key}</TableCell>);
  }

  res.push(<TableCell key="R" align="center">R</TableCell>);
  res.push(<TableCell key="H" align="center">H</TableCell>);
  res.push(<TableCell key="E" align="center">E</TableCell>);

  return res;
}

const createTableCells = (innings, team) => {
  const res = [];

  for(let key=1; key <= innings; key++){
    res.push(<TableCell key={key} align="center">{team.innings[key]}</TableCell>);
  }

  res.push(<TableCell key="R" align="center">{team.totals["R"]}</TableCell>);
  res.push(<TableCell key="H" align="center">{team.totals["H"]}</TableCell>);
  res.push(<TableCell key="E" align="center">{team.totals["E"]}</TableCell>);

  return res;
}

function LineScore(props){
  const game = props.game;
  const innings = (game.linescore && game.linescore.visitor.innings.length > 9) ? game.linescore.visitor.innings.length - 1  : 9;

  return (
    <Box 
      display="flex" 
      style={{ marginTop: 20, overflowX: 'auto' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {createTableHeaders(innings)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell 
              style={{ borderTop: `5px solid #${game.teams?.visitor.color}` }}>
              {game.teams?.visitor.abbr}
            </TableCell>
            {createTableCells(innings, game.linescore?.visitor)}
          </TableRow>
          <TableRow>
            <TableCell 
              style={{ borderTop: `5px solid #${game.teams?.home.color}` }}>
              {game.teams?.home.abbr}
            </TableCell>
            {createTableCells(innings, game.linescore?.home)}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default LineScore;