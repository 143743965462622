import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  load: {
    width: '100%',
    maxWidth: '350px',
    padding: '20px 0'
  }
}));

//Utils
const isTodayDate = (date) => {
  let d = new Date(date);
  let today = new Date();
  return d.toDateString() === today.toDateString();
};

const getTime = (date) => {
  let d = new Date(date);
  return d.toLocaleString('en-us', { hour: '2-digit', minute: '2-digit' });
}

function NewGame(props){
  const classes = useStyles();
  const date = (props.game.full_date ? props.game.full_date : props.game.date).replaceAll('-', '/');
  const isToday = isTodayDate(date);
  const time = props.game.time ? props.game.time : getTime(date);

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      height="50vh">
      <div className={classes.load}>
        <LinearProgress />
      </div>
      <Typography variant="h6">
        Game has not started yet
      </Typography>
      {(isToday)
        ? <Typography variant="h6">
            Starting time: {time}
          </Typography>
        : <Button 
            variant="contained" 
            color="primary"
            href={`${process.env.REACT_APP_WP_URL}schedule/`}
            style={{ marginTop: 20 }}>
            View Schedule
          </Button>
      }
    </Box>
  );
}

export default NewGame;