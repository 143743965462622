import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

function Score(props){
  const {game} = props;

  return (
    <Box 
      display="flex" 
      flexWrap="wrap" 
      justifyContent="center">
      <Box 
        display="flex" 
        alignItems="center" 
        style={{ margin: "0 10px" }}>
          <Avatar 
            alt={game.teams?.visitor.name} 
            src={game.teams?.visitor.logo} />
          <Typography 
            variant="h5" 
            style={{ padding: 10 }}>
            {game.teams?.visitor.name}
          </Typography>
          <Chip label={game.score?.visitor} />
      </Box>
      <Box 
        display="flex" 
        alignItems="center" 
        style={{ margin: "0 10px" }}>
          <Avatar 
            alt={game.teams?.home.name} 
            src={game.teams?.home.logo} />
          <Typography 
            variant="h5" 
            style={{ padding: 10 }}>
            {game.teams?.home.name}
          </Typography>
          <Chip label={game.score?.home} />
      </Box>
    </Box>
  );
}

export default Score;