import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

//General values
const bHeaders = ['AB', 'R', 'H', 'RBI', 'BB', 'SO'];
const pHeaders = ['IP', 'PC', 'H', 'R', 'ER', 'BB', 'SO'];

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: theme.palette.grey[100]
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const createLineupHeaders = (type) => {
  const headers = type === 'pitchers' ? pHeaders : bHeaders;
  return headers.map((item, index) => {
    return (
      <TableCell key={index} align="center">
        {item}
      </TableCell>
    )
  });
};

const createLineup = (game, team = 'home', type = 'batters') => {
  const keys = type === 'pitchers' ? pHeaders : bHeaders;
  const batters = game.lineups[team] ? (type === 'batters' ? game.lineups[team].hittersbyplayer : game.lineups[team][type]) : [];

  return batters.map((batter, index) => {
    return (
      <TableRow key={index}>
        <TableCell style={{ textIndent: (batter.subOrder > 0) ? '10px' : '0' }}>
          {`${batter.number} ${batter.lineupName}`}
        </TableCell>
        {keys.map(key => {
          return (
            <TableCell key={key} align="center">
              {getScoreVal(game.playerscores, key, type, batter)}
            </TableCell>
          )  
        })}
      </TableRow>
    )
  });
};

const getScoreVal = (scores, score, type, batter) => {
  let ids = batter.ids ? batter.ids.split(',') : [batter.id];
  let res = 0;

  ids.map(id => {
    if(scores && scores[type]){
      if(scores[type][id] && !isNaN(scores[type][id][score]))
        res += scores[type][id][score];
    }
    return id;
  });

  return convertScore(score, res);
}

const convertScore = (type, value) => {
  if(type === 'IP')
      return `${Math.floor(value / 3)}.${value % 3}`;

  return value;
};

function LineupTabs(props){
  const { 
    game, 
    battersTab, 
    pitchersTab, 
    battersTabChange, 
    pitchersTabChange} = props;
    
  return (
    <React.Fragment>
      <AppBar 
        position="static" 
        style={{ marginTop: 20 }}>
        <Tabs 
          value={battersTab} 
          onChange={battersTabChange}>
          <Tab label={game.teams?.visitor.shortname} /> 
          <Tab label={game.teams?.home.shortname} /> 
        </Tabs>
      </AppBar>
      <TabContent 
        game={game} 
        currentTab={battersTab} 
        number={0} 
        team="visitor" 
        type="batters" />
      <TabContent 
        game={game} 
        currentTab={battersTab} 
        number={1} 
        team="home" 
        type="batters" />
      <AppBar 
        position="static" 
        style={{ marginTop: 20 }}>
        <Tabs 
          value={pitchersTab} 
          onChange={pitchersTabChange}>
          <Tab label={game.teams?.visitor.shortname} /> 
          <Tab label={game.teams?.home.shortname} /> 
        </Tabs>
      </AppBar>
      <TabContent 
        game={game} 
        currentTab={pitchersTab} 
        number={0} 
        team="visitor" 
        type="pitchers" />
      <TabContent 
        game={game} 
        currentTab={pitchersTab} 
        number={1} 
        team="home" 
        type="pitchers" />
    </React.Fragment>
  )
}

function TabContent(props){
  const {
    game, 
    currentTab, 
    number, 
    team, 
    type } = props;
  const classes = useStyles();

  return (
    <Box 
      hidden={currentTab !== number} 
      p={2} 
      className={classes.tab}>
      <Box className={classes.tableWrapper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell 
                style={{ minWidth: "120px", textTransform: 'capitalize' }}>
                {type}
              </TableCell>
              {createLineupHeaders(type)}
            </TableRow>
          </TableHead>
          <TableBody>
            {createLineup(game, team, type)}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default LineupTabs;