import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading(){
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      height="50vh">
      <CircularProgress style={{ 'margin': 20 }} />
      <Typography variant="h5">
        Loading
      </Typography>
    </Box>
  );
}

export default Loading;