import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function InvalidGame(){
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      alignItems="center" 
      height="50vh">
      <Typography variant="h6">
        Invalid game ID
      </Typography>
    </Box>
  );
}

export default InvalidGame;