import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00263e',
    },
    secondary: {
      main: '#009845'
    }
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 'bold'
      },
      sizeSmall: {
        paddingLeft: 4,
        paddingRight: 4
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'gray'
      }
    },
    MuiChip: {
      root: {
        height: '40px',
        borderRadius: '20px',
        fontSize: '1.5rem', 
        lineHeight: '2rem'
      }
    }
  }
});

export default theme;